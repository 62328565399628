import Cookies from "js-cookie";

export const onClientEntry = () => {
  const googleConsent = Cookies.get("googleConsent");

  const script = document.createElement("script");
  script.src = `https://www.googletagmanager.com/gtag/js?id=AW-932983784`;
  script.async = true;
  script.id = "Gtag_4537452543";
  document.head.appendChild(script);

  if (googleConsent === "accepted") {
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }

      gtag("consent", "default", {
        ad_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
        analytics_storage: "denied",
        wait_for_update: 500,
      });

      gtag("js", new Date());

      gtag("config", "AW-932983784", {
        anonymize_ip: true,
        cookie_expires: 365,
      });

      console.log("consent default");

      gtag("consent", "update", {
        ad_user_data: "granted",
        ad_storage: "granted",
        ad_personalization: "granted",
        analytics_storage: "granted",
      });
    };
  }
};
